import { essayEventMapper } from "../mappers/essayMappers"
import Provider from "./Provider"
import { errorHandler } from "./errorHandler"

export const getToDoEssayEvents = () => {
  return new Promise((resolve, reject) => {
    Provider.get("/todo-teewrite-events")
      .then((response) => {
        resolve(essayEventMapper(response))
      })
      .catch((error) => {
        !errorHandler(error) && reject(error)
      })
  })
}

export const postEssayAnswer = (exercise_id, order, answer) => {
  return new Promise((resolve, reject) => {
    const DTO = { exercise_id, order, answer }
    Provider.post("/essay/post-essay-answer", DTO)
      .then((response) => resolve(response))
      .catch((error) => {
        !errorHandler(error) && reject(error)
      })
  })
}

export const postBrainstorming = (
  exercise_id,
  data,
  time_spent,
  final_post
) => {
  return new Promise((resolve, reject) => {
    const DTO = { exercise_id, data }
    if (final_post) {
      DTO.time_spent = time_spent
      DTO.final_post = final_post
    }
    Provider.post("/teewrite/post-brainstorm", DTO)
      .then((response) => resolve(response))
      .catch((error) => {
        !errorHandler(error) && reject(error)
      })
  })
}

export const postTempDraft = (exercise_id, draft, time_spent) => {
  return new Promise((resolve, reject) => {
    const DTO = { exercise_id, draft, time_spent }
    Provider.post("/teewrite/post-tmp-draft", DTO)
      .then((response) => resolve(response))
      .catch((error) => {
        !errorHandler(error) && reject(error)
      })
  })
}

export const postMainDraft = (exercise_id, draft, time_spent) => {
  return new Promise((resolve, reject) => {
    const DTO = { exercise_id, draft, time_spent }
    Provider.post("/teewrite/main-draft-process", DTO)
      .then((response) => resolve(response))
      .catch((error) => {
        !errorHandler(error) && reject(error)
      })
  })
}

export const pollingTeewrite = (exercise_id, type) => {
  return new Promise((resolve, reject) => {
    const DTO = { exercise_id, type }
    Provider.post("/teewrite/polling", DTO)
      .then((response) => resolve(response))
      .catch((error) => {
        !errorHandler(error) && reject(error)
      })
  })
}

export const getWritingTasksByStudentId = (studentId) => {
  return new Promise((resolve, reject) => {
    Provider.get(`/teewrite/get-writing-tasks-by-student/${studentId}`)
      .then((response) => {
        // resolve(essayEventMapper(response))
        resolve(response)
      })
      .catch((error) => {
        !errorHandler(error) && reject(error)
      })
  })
}

export const getWritingTaskById = (writingTaskId) => {
  return new Promise((resolve, reject) => {
    Provider.get(`/teewrite/get-writing-task/${writingTaskId}`)
      .then((response) => {
        // resolve(essayEventMapper(response))
        resolve(response)
      })
      .catch((error) => {
        !errorHandler(error) && reject(error)
      })
  })
}
