export const production = false

const STUDENTS_API_SUFIX = "/app-student"
const PARENT_API_SUFIX = "/parent"

// LOCAL
const API_URL_LOCAL = "http://localhost:8000/api"

// DEV
const API_URL_DEV = "https://api.dev.teeread.com/api"

// PRODUCTION
const API_URL_PRODUCTION = "https://api.teeread.com/api"

export const API_URL_GENERAL = production ? API_URL_PRODUCTION : API_URL_DEV

export const API_URL = production ? `${API_URL_PRODUCTION}${STUDENTS_API_SUFIX}` : `${API_URL_DEV}${STUDENTS_API_SUFIX}`

export const API_URL_PARENT = production ? `${API_URL_PRODUCTION}${PARENT_API_SUFIX}` : `${API_URL_DEV}${PARENT_API_SUFIX}`