import React from "react"
import { Button as Ripple, Spinner } from "reactstrap"
import PlayAudio from "../helpers/PlayAudio"

export default class Button extends React.Component {
  onBtnClick = () => {
    // if (!this.props.noSound) PlayAudio(this.props.sound || "Drop")
    if (this.props.onClick) this.props.onClick()
  }
  render() {
    const spinnerSize = this.props.spinnerSize || 30
    return (
      <Ripple
        id={this.props.buttonId}
        innerRef={this.props.buttonRef}
        className={this.props.className || ""}
        size={this.props.size || "lg"}
        color={
          this.props.disabled
            ? "secondary"
            : this.props.containerColor ?? "success"
        }
        disabled={this.props.disabled ? this.props.disabled : false}
        style={{
          ...{
            // color: "red",
            fontWeight: "bold",
            marginTop: 20,
            alignSelf: "center",
          },
          ...this.props.style,
        }}
        type={this.props.type ? this.props.type : undefined}
        onClick={this.onBtnClick}
      >
        {this.props.loading ? (
          <Spinner
            color="primary"
            style={{
              height: spinnerSize,
              width: spinnerSize,
              margin: "0 auto",
            }}
          />
        ) : (
          this.props.text
        )}
      </Ripple>
    )
  }
}
