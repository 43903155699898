import React from "react"
import PlayAudio from "../helpers/PlayAudio"

export default function SideModal({
  close = () => {},
  visible = false,
  children,
}) {
  const closeModal = () => {
    // PlayAudio("Drop")
    close()
  }

  return [
    <div
      key="background"
      style={{
        position: "fixed",
        top: 0,
        right: "-65vw",
        zIndex: 5,
        width: "65vw",
        height: "100vh",
        backgroundColor: "rgba(0,0,0,0.2)",
        transform: visible && `translate(-100vw)`,
        transition: "all 0.5s ease-out",
        justifyContent: "flex-end",
      }}
      onClick={closeModal}
    />,
    <div
      key="modal"
      style={{
        position: "fixed",
        top: 0,
        right: "-35vw",
        zIndex: 5,
        width: "35vw",
        height: "100vh",
        backgroundColor: "rgba(0,0,0,0.2)",
        transform: visible && `translate(-35vw)`,
        transition: "all 0.5s ease-out",
      }}
    >
      <div
        style={{
          position: "absolute",
          width: "35vw",
          height: "100vh",
          overflowY: "auto",
          borderTopLeftRadius: 15,
          backgroundColor: "white",
          zIndex: 10,
        }}
      >
        <div
          className="p-2"
          style={{
            marginTop: -15,
            borderTopLeftRadius: 15,
            backgroundColor: "white",
            overflowY: "auto",
          }}
        >
          {children}
        </div>
      </div>
    </div>,
  ]
}
