import { EVENT_TYPES } from "../../helpers/Constants"

export function essayEventMapper(event) {
  if (!event) return undefined
  return {
    id: event._id,
    type: EVENT_TYPES.ESSAY,
    exercise: event.exercise,
    score: event.exercise.score,
    task: event.exercise.task,
    topic: event.exercise.topic,
    deadline: event.deadline
  }
}
